<template>
  <div>
    <div v-for="stage in stages" :key="stage.ID" class="d-flex flex-column text-white">
      <div class="d-flex text-center text-uppercase py-2" :class="dataSport.textClass">
        <div class="flex-shrink-1" style="width: 50px;"></div>
        <div class="w-100 pl-2" v-if="$i18n.locale === 'ru'">{{stage.title}}</div>
        <div class="w-100 pl-2" v-else>{{stage.title_eng}}</div>
      </div>
      <MlbStageGames :games="stage.games" :tourid="tourid" :dataSport="dataSport"></MlbStageGames>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MlbToursStages",
    props: ['stages','tourid','dataSport'],
    components: {
      MlbStageGames: () => import("@/components/blocks/MlbStageGames.vue"),
    },
  }
</script>

<style scoped>

</style>